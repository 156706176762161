import { useEffect, useState } from "react"
import { getBoard } from "../API/API";
import "../aanspreekpunt/aanspreekpunt.css"
import {Card} from "antd";
import "../css/General.css"
import "../frontpage/FrontPage.css"
import "../frontpage/GameCard.css"
import api from '../frontpage/images/api.png';
import { Tooltip } from 'antd';


export const Aanspreekpunt = (props) => {


    const [boardMember, setBoardMember] = useState({});

    useEffect(() => {
        const fetchMember = async () => {
            const boardData = await getBoard();
            const api = boardData.find(obj => obj['function'].includes("API"));
            console.log(api);
            setBoardMember(api);
        }

        fetchMember();
    }, []);

    return (
        <div className="api-div container">
            <div className="body">
                <header>
                    <h1>Aanspreekpunt Integriteit</h1>
                </header>

                <main>
                    <p>
                        Welkom bij Volley Team Temse! Wij hechten grote waarde aan integriteit en ethisch gedrag in al onze activiteiten. Als onderdeel van ons voortdurende streven naar transparantie en verantwoordelijkheid, hebben we een toegewijd Aanspreekpunt Integriteit in het leven geroepen om u te ondersteunen bij vragen, zorgen of meldingen met betrekking tot integriteit binnen onze organisatie.
                    </p>

                    <h2>Wat is het Aanspreekpunt Integriteit?</h2>
                    <p>
                        Ons Aanspreekpunt Integriteit is er om een vertrouwde en veilige ruimte te bieden waar medewerkers, klanten, leveranciers en andere belanghebbenden problemen met betrekking tot integriteit kunnen bespreken. Dit omvat zaken zoals ethisch gedrag, compliance met regelgeving, anti-corruptie, verantwoord zakendoen en meer. Het Aanspreekpunt Integriteit fungeert als een onafhankelijk en neutraal platform om dergelijke kwesties aan te kaarten.
                    </p>
                    <h2>Hoe kunt u contact opnemen met het Aanspreekpunt Integriteit?</h2>
                    <p>
                        U kunt op verschillende manieren contact opnemen met ons Aanspreekpunt Integriteit:
                    </p>
                    <ol>
                        <li><strong>Telefonisch contact:</strong> Bel ons {boardMember.phone_number} om direct in contact te komen met ons integriteitsteam.</li>
                        <li><strong>E-mail:</strong> Stuur een e-mail naar <a href="mailto:${boardMember.email}">{boardMember.email}</a> om uw zorgen of meldingen discreet met ons te delen.</li>
                        <li><strong>Persoonlijk gesprek:</strong> Als u de voorkeur geeft aan een persoonlijk gesprek, neem dan contact met ons op om een afspraak te maken.</li>
                    </ol>

                    <h2>Vertrouwelijkheid en anonimiteit</h2>
                    <p>
                        Wij begrijpen dat het melden van integriteitskwesties gevoelig kan zijn. Daarom garanderen wij de vertrouwelijkheid van uw melding en respecteren wij uw recht op anonimiteit, indien gewenst. Uw informatie wordt alleen gedeeld met personen die betrokken zijn bij het onderzoeken en oplossen van de kwestie.
                    </p>

                    <h2>Onze toewijding aan integriteit</h2>
                    <p>
                        Bij Volley Team Temse zijn we vastbesloten om een cultuur van integriteit en ethiek te bevorderen. Het melden van integriteitskwesties is een belangrijk onderdeel van dit streven. Uw bijdrage is essentieel om onze organisatie te helpen groeien op een basis van vertrouwen, eerlijkheid en verantwoordelijkheid.
                    </p>
                </main>

                <footer>
                    <p>
                        Dank u voor uw betrokkenheid bij het handhaven van onze hoge normen voor integriteit en ethisch gedrag. Neem gerust contact met ons op via het Aanspreekpunt Integriteit als u vragen, zorgen of meldingen heeft. Samen werken we aan een integere en ethische toekomst voor Volley Team Temse.
                    </p>
                    <p>Met vriendelijke groet,</p>
                    <p>{boardMember.name}</p>
                    <p>Aanspreekpunt Integriteit - Volley Team Temse</p>
                </footer>
                <h1>Gezondheid en ethiek volley vlaanderen</h1>
            <div className="picture-div-wrapper">
                <a className="clickable-link" href="https://volleyvlaanderen.be/Integriteit/Federatie/API">
                    <Tooltip title='Aanspreekpunt integriteit volley vlaanderen'>
                        <img src={api} alt="api" className="trooper"></img>
                    </Tooltip>
                </a>
            </div>
            </div>
            <Card
                cover={<img alt={boardMember.name} src={boardMember.image} />}
                hoverable
                style={{
                    // maxWidth: '300px', // Adjust the maximum width of the card as needed
                    margin: '10px', // Optional: Add margin for spacing
                    // borderRadius: '25px',
                    marginTop: '150px',
                    height: '130%',
                }}
            >
                <Card.Meta title={boardMember.name} description={boardMember.email} />
            </Card>
        </div>
    )
}
